import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../src/Images/ff.png';
import '../src/Navbar.css';

const Navbar = () => {
  return (
<div>
  <nav className="navbar navbar-expand-lg bg-black">
    <div className="container-fluid">
      {/* Logo on the left */}
      <Link to="/" className="navbar-brand fs-4 px-4">
        <img className="logo" src={Logo} alt="Site logo" />
      </Link>

      {/* Hamburger menu toggle button */}
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <i className="fas fa-bars text-light"></i>
      </button>

      {/* Navbar links in the center */}
      <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
        <ul className="navbar-nav">
          <li className="nav-item active">
            <a className="nav-link text-light" href="#home">Home</a>
          </li>
          <li className="nav-item">
            <a className="nav-link text-light" href="#about">About</a>
          </li>
          <li className="nav-item">
            <a className="nav-link text-light" href="#services">Services</a>
          </li>
          <li className="nav-item">
            <a className="nav-link text-light" href="#skills">Skills</a>
          </li>
          <li className="nav-item">
            <a className="nav-link text-light" href="#experience">Experience</a>
          </li>
          <li className="nav-item">
            <a className="nav-link text-light" href="#portfolio">Portfolio</a>
          </li>
        </ul>
      </div>

      {/* Hire Me button on the right, outside the collapse */}
      <div className="d-flex">
        <button className="hirebutton btn btn-primary px-4" onClick={() => document.getElementById('hire').scrollIntoView({ behavior: 'smooth' })}>Hire Me</button>
      </div>
    </div>
  </nav>
</div>

  
  );
};

export default Navbar;
